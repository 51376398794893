import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/nonun/...Active Code/new-gatsby-site/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Announcement`}</h2>
    <p>{`This very site, that you are viewing is now a PWA and can work offline. The offline part still has a lot of room to improve but I would love if you were to check it out and give me some suggestions. You can see the contact links in the footer down below.`}</p>
    <h2>{`Weekly favourites`}</h2>
    <p><strong parentName="p">{`Instapaper`}</strong>{` - Instapaper is essentially a save to read-later app. The main reason I have started using Instapaper for is to take notes on articles. Instapaper has a very similar integration with Readwise as the Kindle does. So, I can use my `}<a parentName="p" {...{
        "href": "/pages/content/articles/my-note-taking-workflow/"
      }}>{`note taking workflow`}</a>{` to extract the juicy knowledge from articles and websites`}</p>
    <p><strong parentName="p">{`Julian Shapiro`}</strong>{` - `}<a parentName="p" {...{
        "href": "https://www.julian.com/"
      }}>{`Julian Shapiro`}</a>{`'s website consists of "handbooks of knowledge". All of them are free and they touch on different topics from writing to muscle building. I have been deeply interested in the writing part and I hope that in the coming articles my writing improves`}</p>
    <h2>{`Quote of the week`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7d6fa387080637a93e31f0edee84084a/798d4/Quote-11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsSAAALEgHS3X78AAABSUlEQVQoz62Ry07CQBSGff8nMPESNZGNC2NAMUYXunFhUORSCDQ0BVqwBGhLL9NC+znTYIgbjYkn+TL/OZP558yZvTzP+a9QXntK+L6PJ3E9F3+1QiQJYRyRZzmjlUN9rtMTE3qxTV9M0ZMPusGYjj+U+YRuZLHMwsK0MJxYNt2WVtCqNxkZJmNzxHoleDYbnLRvKGl3nCv695x1bjmVFGu7ykGzTD+yd4aOcBmkDmY6Qw9sjETqzRxDOHTXE1qZxWswQNvYLInwMgkx/halRb7eGT4Oauy/XXIxfOLovUxJf5BdVTluVCj1pa5XOKxdcT19Idke/HGGYZ4wzwJcebvqYJmHLGS+kKuqz9Z+oRdyThsy0jQlCAM5a4EQotCqVnT47Zd/+/Dtvud5GIaBZY2xJR1NY+Y4uycr07/wFSIOSURUkMpuVXwCNX5fVvRUlRwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Quote",
            "title": "Quote",
            "src": "/static/7d6fa387080637a93e31f0edee84084a/5a190/Quote-11.png",
            "srcSet": ["/static/7d6fa387080637a93e31f0edee84084a/772e8/Quote-11.png 200w", "/static/7d6fa387080637a93e31f0edee84084a/e17e5/Quote-11.png 400w", "/static/7d6fa387080637a93e31f0edee84084a/5a190/Quote-11.png 800w", "/static/7d6fa387080637a93e31f0edee84084a/798d4/Quote-11.png 976w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Tweet of the week`}</h2>
    <blockquote className="twitter-tweet" data-dnt="true">
  <p lang="en" dir="ltr">
    Tip for creators🧐
    <br />
    <br />
    Whenever you don’t feel like publishing your work because you think it’s not
    “original” or someone else already made something similar, remind yourself there
    are &gt;1000 biographies of Winston Churchill <br />
    <br />
    Publish it anyways!
  </p>
  &mdash; Shu Omi (@shuomi3) <a href="https://twitter.com/shuomi3/status/1352943755104448512?ref_src=twsrc%5Etfw">
    January 23, 2021
  </a>
    </blockquote>
    <h2>{`Books that I will be reading this week`}</h2>
    <center>
      <p><a parentName="p" {...{
          "href": "https://www.amazon.com/Storyworthy-Engage-Persuade-through-Storytelling-ebook/dp/B07CV2PFYJ/ref=tmm_kin_swatch_0"
        }}><span parentName="a" {...{
            "className": "gatsby-img-attributes",
            "style": {
              "display": "inline-block",
              "width": "325px",
              "height": "500px"
            }
          }}><span parentName="span" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "696px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "154.50000000000003%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAwADAAAAAAAAAAAAAAAAAAMEAQIF/8QAFgEBAQEAAAAAAAAAAAAAAAAABAMC/9oADAMBAAIQAxAAAAGsowes5uHtQzmDStEkt//EABoQAAMBAAMAAAAAAAAAAAAAAAABAhEQEiH/2gAIAQEAAQUCzSpc8S3KqnSrwil1dSht09NNP//EABoRAAMAAwEAAAAAAAAAAAAAAAABAgMTMUH/2gAIAQMBAT8Bu34TweSGzfJ//8QAGhEAAgMBAQAAAAAAAAAAAAAAAAECAxEUIf/aAAgBAgEBPwGFSzWS8Yq5pYc0z//EABwQAAICAgMAAAAAAAAAAAAAAAARARACISAxM//aAAgBAQAGPwLRuoWDZ5TSk7Hw/8QAHhAAAgIBBQEAAAAAAAAAAAAAAREAIRBBUWFxgZH/2gAIAQEAAT8hBkm8iFyHhuQgyRLt94DCkw0dJeKRqCXbqGJU4N2D8T//2gAMAwEAAgADAAAAEEjVv//EABsRAQACAgMAAAAAAAAAAAAAAAEAESExgaHw/9oACAEDAQE/ELSSlK7lgz1Aygfcz//EABoRAQEAAgMAAAAAAAAAAAAAAAEAETGBofD/2gAIAQIBAT8QQIAoWiO5Ryp7i//EAB4QAQEAAgICAwAAAAAAAAAAAAERACExUUGRYXGB/9oACAEBAAE/EC1D0MG6Q0NwjsdYmUQUjePqZPoCIpKfGDEyHkUcYUjEF3lKt4NlyY6WjmGcun4ZM0HrIcHpn//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "story-worthy",
                "title": "story-worthy",
                "src": "/static/6be1398c33b5c005d909d0192bc84c90/63ea1/storyworthy.jpg",
                "srcSet": ["/static/6be1398c33b5c005d909d0192bc84c90/e07e9/storyworthy.jpg 200w", "/static/6be1398c33b5c005d909d0192bc84c90/066f9/storyworthy.jpg 400w", "/static/6be1398c33b5c005d909d0192bc84c90/63ea1/storyworthy.jpg 696w"],
                "sizes": "(max-width: 696px) 100vw, 696px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span></span></a></p>
    </center>
    <h2>{`Articles coming up this week`}</h2>
    <p>{`Articles/How I boost my productivity on a computer`}</p>
    <p>{`Everyone uses a computer. During the lockdown, even strictly analog institutes like schools and government offices have had to go to a computer. This has lead a new surge of people who had never touched computers in their lives to have no choice but to use them...`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      